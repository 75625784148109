<template>
  <iframe style="width: 100%" :width="theWidth" :height="theHeight" :src="theLink" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</template>

<script>

export default {
  name: 'YouTubeVideo',
  props: ['link', 'width', 'height'],
  computed: {
    theLink () {
      return 'https://www.youtube.com/embed/' + this.link
    },
    theHeight () {
      return this.height ? this.height : 315
    },
    theWidth () {
      return this.width ? this.width : 560
    }
  }
}
</script>
